import React, { Component } from 'react';
import 'antd/dist/antd.css';
import { Table, Row, Col,Card } from 'antd';
import { list } from '../../api/Apis';
import AppConfigs from '../../Config/AppConfig';

// const Search = Input.Search;
class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      tablelength: 24,
      detailswidth: 0,
      contactFormSubmissions: [],
    }
  }

  componentDidMount = () => {
    this.getContactForm();
  }

  getContactForm = () => {
    this.setState({
      dataLoadingStatus: true
    })
    list(AppConfigs["entityNames"]["contactForm"])
      .then(result => {
        if (result.success) {
          this.setState({
            contactFormSubmissions: result.data,
            dataLoadingStatus: false
          });
        } else {
          this.setState({
            dataLoadingStatus: false
          });
          console.log(result.errors);
        }
      });
  }

  render() { 
    const columns = [
      {
        title: 'From Name',
        dataIndex: 'from',
        key: 'from',
        show: true,
        render: (from, record) => {
          return (
            <span>{from}</span>
          )
        }
      },{
        title: 'To Name',
        dataIndex: 'toName',
        key: 'toName',
        show: true,
        render: (toName, record) => {
          return (
            <span>{toName}</span>
          )
        }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        show: true,
        render: (email, record) => {
          return (
            <span>{email}</span>
          )
        }
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        show: true,
        render: (phone, record) => {
          return (
            <span>{phone}</span>
          )
        }
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        show: true,
        width: 300,
        render: (subject, record) => {
          return (
            <span>{subject}</span>
          )
        }
      },
      {
          title: 'Message',
          dataIndex: 'message',
          key: 'message',
          show: true,
          width: 300,
          render: (message, record) => {
            return (
              <span>{message}</span>
            )
          }
      }
    ];
    return (
      <div className="registration-cont">
        <div className="filter">
          <Row >
            <Col span={this.state.tablelength} style={{ padding: '10px', marginTop:"20px" }}>
            <Card>
              <Table
                columns={columns.filter(col => col.show !== false)}
                dataSource={this.state.contactFormSubmissions}
                loading={this.state.dataLoadingStatus}
                rowKey={record => record.id}
                style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
              />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
export default ContactForm;