import Dashboard from "../Containers/Dashboard/Dashboard"
import Users from "../Containers/Users/Users";
import UserDetails from "../Containers/Users/UserDetails";
import AddUser from "../Containers/Users/UserOperations";
import Advertisements from "../Containers/Ads/Advertisements";
import AdOperation from "../Containers/Ads/AdOperation";
import Subscriptions from "../Containers/Subscriptions/Subscriptions";
import AddSubscriptions from "../Containers/Subscriptions/SubscriptionOperations";
import ContactForm from "../Containers/ContactForm/ContactForm";
import MissedLeads from "../Containers/MissedLeads/MissedLeads";
import AddApiFields from "../Containers/Configurations/AddApiFields";
import MortgageCal from "../Containers/Mortgage/MortgageCal";

const AppRoutes = [
  {
    key: "001",
    exact: true,
    path: "/application/users",
    name: "Users",
    component: Users,
  },
  {
    key: "002",
    exact: true,
    path: "/application/addUser",
    name: "Add New User",
    component: AddUser,
  },
  {
    key: "003",
    exact: true,
    path: "/application/advertisements",
    name: "Ads",
    component: Advertisements,
  },
  {
    key: "004",
    exact: true,
    path: "/application/addAdvertisement",
    name: "Add new ad",
    component: AdOperation,
  },
  {
    key: "005",
    exact: true,
    path: "/application/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
  },
  {
    key: "006",
    exact: true,
    path: "/application/subscriptionOperations",
    name: "Add Subscriptions",
    component: AddSubscriptions,
  },
  {
    key: "006",
    exact: true,
    path: "/application/contactForm",
    name: "Contact Form Submissions",
    component: ContactForm,
  },
  {
    key: "007",
    exact: true,
    path: "/application/missedLeads",
    name: "Missed Leads",
    component: MissedLeads,
  },
  {
    key: "008",
    exact: true,
    path: "/application/configuration",
    name: "API configuration",
    component: AddApiFields,
  },
  {
    key: "009",
    exact: true,
    path: "/application/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    key: "010",
    exact: true,
    path: "/application/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    key: "011",
    exact: true,
    path: "/application/viewUser/:id",
    name: "UserDetails",
    component: UserDetails,
  },
  {
    key: "012",
    exact: true,
    path: "/application/mortgage",
    name: "UserDetails",
    component: MortgageCal,
  },
];
export default AppRoutes;
