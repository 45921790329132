import React, { Component } from "react";
import "antd/dist/antd.css";
import { withRouter } from "react-router";
import {
  Form,
  Table,
  Input,
  Divider,
  Row,
  Col,
  Button,
  Menu,
  Dropdown,
  Modal,
  Select,
  Card,
  Tabs,
  Avatar,
  Collapse,
  Tooltip,
} from "antd";
import {
  HomeOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  ShopOutlined,
  DollarCircleOutlined,
  QuestionCircleOutlined,
  UserAddOutlined,
  HeartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import { list, update, noAuthGetDetails } from "../../api/Apis";
import AppConfigs from "../../Config/AppConfig";
const { Option } = Select;
const Search = Input.Search;
const { TabPane } = Tabs;
const { Panel } = Collapse;

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      tablelength: 24,
      detailswidth: 0,
      subscritpionModal: false,
      selectedUser: "",
      selectedUserProfile: "",
      subscriptionPacks: [],
      users: [],
      totalUsers: [],
      selectedRecord: {},
      defaultProfile: "",
      defaultProfileSubscriptionPack: "",
      searchVal: "",
      showAnalytics: false,
      analyticsData: "",

      vendorsList: [],
      lendersList: [],
      agentsList: [],
      buyersList: [],
      sellersList: [],
    };
  }

  componentDidMount = () => {
    this.getUsers();
  };

  getUsers = () => {
    this.setState({
      dataLoadingStatus: true,
    });
    let vendorsList = [];
    let lendersList = [];
    let agentsList = [];
    let buyersList = [];
    let sellersList = [];
    list(AppConfigs["entityNames"]["users"]).then((result) => {
      if (result.success) {
        list(AppConfigs["entityNames"]["subscriptionPacks"]).then(
          (subResult) => {
            if (subResult.success) {
              result.data.map((u) => {
                u.userProfile == "V"
                  ? vendorsList.push(u)
                  : u.userProfile == "L"
                  ? lendersList.push(u)
                  : u.userProfile == "AG"
                  ? agentsList.push(u)
                  : u.userProfile == "B"
                  ? buyersList.push(u)
                  : sellersList.push(u);

                if (u["subscriptionPack"]) {
                  let subPack = subResult.data.filter(
                    (s) => s["id"] === u["subscriptionPack"]
                  )[0];
                  u["subscriptionPack"] = subPack && subPack["planTitle"];
                  u["subscriptionValue"] = subPack && subPack["price"];
                } else {
                  u["subscriptionPack"] = "";
                  u["subscriptionValue"] = 0;
                }
                u["userStatus"] = u["recordStatus"] ? "Active" : "In-Active";
                if (u["referedById"]) {
                  let res = result.data.filter(
                    (s) => s["id"] === u["referedById"]
                  )[0];
                  u["referedById"] = res["firstName"] + " " + res["lastName"];
                }
                return u;
              });

              this.setState({
                subscriptionPacks: subResult.data,
                users: result.data,
                totalUsers: result.data,
                dataLoadingStatus: false,
                vendorsList: vendorsList,
                lendersList: lendersList,
                agentsList: agentsList,
                buyersList: buyersList,
                sellersList: sellersList,
              });
            }
          }
        );
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        console.log(result.errors);
      }
    });
  };

  searchData = async (event, searchType, id) => {
    let searcingValue = searchType === "text" ? event.target.value : event;
    let totalUsers = [];
    await this.setState({
      [id]: searcingValue,
    });
    searcingValue = searcingValue.toLowerCase();
    if (searchType === "text") {
      this.setState({
        defaultProfile: "",
        defaultProfileSubscriptionPack: "",
      });
      totalUsers = this.state.users.filter(
        (user) =>
          (user["firstName"] &&
            user["firstName"].toLowerCase().indexOf(searcingValue) > -1) ||
          (user["phno"] &&
            user["phno"].toString().indexOf(searcingValue) > -1) ||
          user["email"].toLowerCase().indexOf(searcingValue) > -1 ||
          user["userProfile"].toLowerCase().indexOf(searcingValue) > -1
      );
    } else {
      console.log(
        this.state.defaultProfileSubscriptionPack,
        this.state.defaultProfile
      );
      if (
        this.state.defaultProfileSubscriptionPack !== "" &&
        this.state.defaultProfile !== ""
      ) {
        totalUsers = this.state.users.filter(
          (user) =>
            user["userProfile"]
              .toLowerCase()
              .indexOf(this.state.defaultProfile.toLowerCase()) > -1 &&
            user["subscriptionValue"] ===
              this.state.defaultProfileSubscriptionPack
        );
      } else if (
        this.state.defaultProfileSubscriptionPack === "" &&
        this.state.defaultProfile === ""
      ) {
        totalUsers = this.state.users;
      } else if (searchType === "subscription") {
        if (
          this.state.defaultProfileSubscriptionPack === "" &&
          this.state.defaultProfile !== ""
        ) {
          totalUsers = this.state.users.filter(
            (user) =>
              user["userProfile"]
                .toLowerCase()
                .indexOf(this.state.defaultProfile.toLowerCase()) > -1
          );
        } else {
          totalUsers = this.state.users.filter(
            (user) => user["subscriptionValue"] === searcingValue
          );
        }
      } else {
        if (
          this.state.defaultProfile === "" &&
          this.state.defaultProfileSubscriptionPack !== ""
        ) {
          totalUsers = this.state.users.filter(
            (user) =>
              user["subscriptionValue"] ===
              this.state.defaultProfileSubscriptionPack
          );
        } else {
          totalUsers = this.state.users.filter(
            (user) =>
              user["userProfile"].toLowerCase().indexOf(searcingValue) > -1
          );
        }
      }
    }
    // } else {
    //   totalUsers = this.state.users
    // }
    this.setState({
      totalUsers: totalUsers,
    });
  };

  ordersSort(pagination, filters, sorter) {
    let totalUsers = this.state.totalUsers;
    if (sorter["order"] === "descend") {
      if (sorter["columnKey"] === "subscriptionPack") {
        totalUsers = totalUsers.sort((a, b) =>
          parseInt(a["subscriptionValue"]) < parseInt(b["subscriptionValue"])
            ? 1
            : -1
        );
      } else if (sorter["columnKey"] === "recordStatus") {
        totalUsers = totalUsers.sort((a, b) =>
          a["userStatus"] < b["userStatus"] ? 1 : -1
        );
      } else if (sorter["columnKey"] === "fullName") {
        let firstNameUsers = totalUsers
          .filter((u) => u["firstName"])
          .sort((a, b) =>
            a["firstName"].toLowerCase() < b["firstName"].toLowerCase() ? 1 : -1
          );
        totalUsers = [
          ...firstNameUsers,
          ...totalUsers.filter((u) => !u["firstName"]),
        ];
        // totalUsers = totalUsers.sort((a, b) => a["firstName"] && (a["firstName"].toLowerCase() < b["firstName"].toLowerCase()) ? 1 : -1)
      } else if (sorter["columnKey"] === "referedById") {
        let referedByIdUsers = totalUsers
          .filter((u) => u["referedById"])
          .sort((a, b) =>
            a["referedById"].toLowerCase() < b["referedById"].toLowerCase()
              ? 1
              : -1
          );
        totalUsers = [
          ...referedByIdUsers,
          ...totalUsers.filter((u) => !u["referedById"]),
        ];
      } else if (sorter["columnKey"] === "userProfile") {
        totalUsers = totalUsers.sort((a, b) =>
          a["userProfile"].toLowerCase() < b["userProfile"].toLowerCase()
            ? 1
            : -1
        );
      }
    } else if (sorter["order"] === "ascend") {
      if (sorter["columnKey"] === "subscriptionPack") {
        totalUsers = totalUsers.sort((a, b) =>
          parseInt(a["subscriptionValue"]) > parseInt(b["subscriptionValue"])
            ? 1
            : -1
        );
      } else if (sorter["columnKey"] === "recordStatus") {
        totalUsers = totalUsers.sort((a, b) =>
          a["userStatus"] > b["userStatus"] ? 1 : -1
        );
      } else if (sorter["columnKey"] === "fullName") {
        let firstNameUsers = totalUsers
          .filter((u) => u["firstName"])
          .sort((a, b) =>
            a["firstName"].toLowerCase() > b["firstName"].toLowerCase() ? 1 : -1
          );
        totalUsers = [
          ...firstNameUsers,
          ...totalUsers.filter((u) => !u["firstName"]),
        ];
      } else if (sorter["columnKey"] === "referedById") {
        let referedByIdUsers = totalUsers
          .filter((u) => u["referedById"])
          .sort((a, b) =>
            a["referedById"].toLowerCase() > b["referedById"].toLowerCase()
              ? 1
              : -1
          );
        totalUsers = [
          ...referedByIdUsers,
          ...totalUsers.filter((u) => !u["referedById"]),
        ];
      } else if (sorter["columnKey"] === "userProfile") {
        totalUsers = totalUsers.sort((a, b) =>
          a["userProfile"].toLowerCase() > b["userProfile"].toLowerCase()
            ? 1
            : -1
        );
      }
    }
    this.setState({
      totalUsers: totalUsers,
    });
  }

  addSubscription = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          dataLoadingStatus: true,
        });
        console.log(values);
        // return;
        let data = {
          entityData: {
            subscriptionPack: values["subscriptionPack"],
          },
        };
        update(
          AppConfigs["entityNames"]["users"],
          this.state.selectedUser,
          data
        ).then((result) => {
          if (result.success) {
            this.setState({
              subscritpionModal: false,
            });
            this.getUsers();
          } else {
            console.log(result.errors);
          }
        });
      }
    });
  };

  getUserInfo = async () => {
    this.setState({
      dataLoadingStatus: true,
    });
    noAuthGetDetails("getUsers", "b954e640-9154-46ee-8e50-aeb657a8d18a").then(
      (result) => {
        if (result.success) {
          this.setState({
            dataLoadingStatus: false,
            showAnalytics: true,
            analyticsData: result.data,
          });
        } else {
          this.setState({
            dataLoadingStatus: false,
          });
          alert("Something went wrong!");
          console.log(result.errors);
        }
      }
    );
  };

  resultView = (icon, title, result) => {
    return (
      <Card className="mob-card" hoverable>
        <Col span={24}>
          <h1 style={{ textAlign: "center" }}>
            {icon == "homes" ? (
              <HomeOutlined className="ant-icon" />
            ) : icon == "buyers" ? (
              <TeamOutlined className="ant-icon" />
            ) : icon == "agents" ? (
              <UserSwitchOutlined className="ant-icon" />
            ) : icon == "lenders" ? (
              <DollarCircleOutlined className="ant-icon" />
            ) : icon == "market" ? (
              <ShopOutlined className="ant-icon" />
            ) : (
              <HeartOutlined className="ant-icon" />
            )}
          </h1>
        </Col>
        <Col span={24}>
          <div>
            <h4 className="sub-title">{title}</h4>
            <h1 className="result-big">{result}</h1>
          </div>
        </Col>
      </Card>
    );
  };

  render() {
    const { vendorsList, lendersList, agentsList, buyersList, sellersList } =
      this.state;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "Image",
        dataIndex: "profileUrl",
        key: "profileUrl",
        show: true,
        sorter: true,
        render: (profileUrl, record) => {
          return profileUrl && profileUrl !== "" ? (
            <Avatar className="avatar-img-header" src={profileUrl} />
          ) : (
            <Avatar icon={<UserOutlined />} />
          );
        },
      },
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        show: true,
        sorter: true,
        render: (fullName, record) => {
          return <span>{record.firstName + " " + record.lastName}</span>;
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        show: true,
        render: (phone, record) => {
          return <span>{phone}</span>;
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        show: true,
        render: (email, record) => {
          return <span>{email}</span>;
        },
      },
      {
        title: "Profile",
        dataIndex: "userProfile",
        key: "userProfile",
        show: true,
        sorter: true,
        render: (userProfile, record) => {
          return <span>{AppConfigs["USER_TYPES"][userProfile]}</span>;
        },
      },
      {
        title: "Subscription Pack",
        key: "subscriptionValue",
        dataIndex: "subscriptionValue",
        sorter: true,
        render: (subscriptionValue, record) => {
          return (
            <span>
              {subscriptionValue === 0 || subscriptionValue === "0"
                ? "Free"
                : subscriptionValue !== undefined &&
                  `$ ${subscriptionValue}/month`}
            </span>
          );
        },
      },
      {
        title: "Referred By",
        key: "referedById",
        dataIndex: "referedById",
        sorter: true,
        render: (referedById, record) => {
          return <span>{referedById}</span>;
        },
      },
      {
        title: "Status",
        key: "recordStatus",
        dataIndex: "userStatus",
        sorter: true,
        render: (userStatus, record) => {
          return <span>{userStatus}</span>;
        },
      },
      {
        title: "DETAILS",
        key: "id",
        dataIndex: "id",
        show: this.state.show,
        render: (id, record) => (
          <Row gutter={[16, 16]} justify="center">
            <Col span={12}>
              <Link to={"/application/viewUser/" + id}>
                <Tooltip title="View User">
                  <Button style={{ color: "#1891ff" }} shape="circle">
                    <i
                      className="fa fa-line-chart fa-md"
                      aria-hidden="true"
                    ></i>
                  </Button>
                </Tooltip>
              </Link>
            </Col>
            <Col span={12}>
              {(record.userProfile === "AG" ||
                record.userProfile === "V" ||
                record.userProfile === "L") && (
                <Tooltip title="Subscriptions">
                  <Button
                    style={{ color: "green" }}
                    shape="circle"
                    onClick={() => {
                      this.setState({
                        selectedUser: id,
                        selectedUserProfile: record.userProfile,
                        subscritpionModal: true,
                      });
                      this.props.form.setFieldsValue({
                        subscriptionPack: record.subscriptionPack,
                      });
                    }}
                  >
                    <i
                      className="fa fa-credit-card-alt fa-md"
                      aria-hidden="true"
                    ></i>
                  </Button>
                </Tooltip>
              )}
            </Col>
          </Row>
        ),
      },
    ];

    const menu = (id, record) => (
      <Menu>
        <Menu.Item className="blue">
          <span style={{ color: "rgba(0, 0, 0, 0.65)", cursor: "pointer" }}>
            <Link to={"/application/viewUser/" + id}>View</Link>
          </span>
        </Menu.Item>
        {(record.userProfile === "AG" ||
          record.userProfile === "V" ||
          record.userProfile === "L") && (
          <Menu.Item className="blue">
            <span
              style={{ color: "rgba(0, 0, 0, 0.65)", cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  selectedUser: id,
                  selectedUserProfile: record.userProfile,
                  subscritpionModal: true,
                });
                this.props.form.setFieldsValue({
                  subscriptionPack: record.subscriptionPack,
                });
              }}
            >
              Subscription
            </span>
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <div className="registration">
        <div className="filter">
          <Collapse style={{ margin: "12px" }}>
            <Panel header="User Analytics" key="1">
              <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col span={4}>
                  {this.resultView(
                    "fav",
                    "Total",
                    vendorsList.length +
                      lendersList.length +
                      agentsList.length +
                      buyersList.length +
                      sellersList.length
                  )}
                </Col>
                <Col span={4}>
                  {this.resultView("market", "Vendors", vendorsList.length)}
                </Col>
                <Col span={4}>
                  {this.resultView("lenders", "Lenders", lendersList.length)}
                </Col>
                <Col span={4}>
                  {this.resultView("agents", "Agents", agentsList.length)}
                </Col>
                <Col span={4}>
                  {this.resultView("buyers", "Buyers", buyersList.length)}
                </Col>
                <Col span={4}>
                  {this.resultView("buyers", "Sellers", sellersList.length)}
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <Row>
            <Col span={this.state.tablelength} style={{ padding: "10px" }}>
              <Row gutter={[16, 16]}>
                <Col span={7}>
                  <Search
                    className="search"
                    size="large"
                    placeholder="Search"
                    onChange={(event) =>
                      this.searchData(event, "text", "searchVal")
                    }
                    value={this.state.searchVal}
                  />
                </Col>
                <Col span={7} style={{ display: "flex" }}>
                  <Select
                    onChange={(event) =>
                      this.searchData(event, "userProfile", "defaultProfile")
                    }
                    defaultValue="All Status"
                    value={this.state.defaultProfile}
                    notFoundContent={"Not found?!"}
                    style={{ width: "100%" }}
                  >
                    <Option key="" value="">
                      All Profiles
                    </Option>
                    <Option key="B" value="B">
                      Buyer
                    </Option>
                    <Option key="S" value="S">
                      Seller
                    </Option>
                    <Option key="AG" value="AG">
                      Agent
                    </Option>
                    <Option key="V" value="V">
                      Vendor
                    </Option>
                    <Option key="L" value="L">
                      Lender
                    </Option>
                  </Select>
                </Col>

                <Col span={7} style={{ display: "flex" }}>
                  <Select
                    onChange={(event) =>
                      this.searchData(
                        event,
                        "subscription",
                        "defaultProfileSubscriptionPack"
                      )
                    }
                    defaultValue="All Status"
                    value={this.state.defaultProfileSubscriptionPack}
                    notFoundContent={"Not found?!"}
                    style={{ width: "100%" }}
                  >
                    <Option key="" value="">
                      All Subscription Packs
                    </Option>
                    <Option key="25" value="25">
                      $25
                    </Option>
                    <Option key="50" value="50">
                      $50
                    </Option>
                    <Option key="100" value="100">
                      $100
                    </Option>
                    <Option key="0" value="0">
                      Free
                    </Option>
                  </Select>
                </Col>
                <Col span={3} style={{ display: "flex" }}>
                  <Button
                    type="primary"
                    size="default"
                    style={{ width: "100%" }}
                  >
                    <Link to="/application/addUser">
                      <h1 style={{ color: "#fff" }}>
                        <i className="fa fa-plus-circle"></i> Users{" "}
                      </h1>
                    </Link>
                  </Button>
                </Col>
              </Row>
              <Table
                columns={columns.filter((col) => col.show !== false)}
                dataSource={this.state.totalUsers}
                loading={this.state.dataLoadingStatus}
                rowKey={(record) => record.id}
                onChange={(pagination, filters, sorter) => {
                  this.ordersSort(
                    pagination,
                    filters,
                    sorter,
                    this.state.totalUsers
                  );
                }}
              />
            </Col>
          </Row>
        </div>
        <Form>
          <Modal
            visible={this.state.subscritpionModal}
            closable={true}
            footer={[
              <Button
                key="cancel"
                className="cancel"
                onClick={() => {
                  this.setState({ subscritpionModal: false });
                }}
              >
                Cancel
              </Button>,
              <Button
                type="primary"
                key="editSubmit"
                htmlType="submit"
                loading={this.state.dataLoadingStatus}
                onClick={this.addSubscription}
              >
                Save
              </Button>,
            ]}
          >
            <div>
              <Row>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{ textAlign: "right" }}
                >
                  {/* <CloseOutlined onClick={()=>{this.setState({subscritpionModal: false})}} style={{ float: 'right', cursor: "pointer", fontSize: '1.4em' }} /> */}
                </Col>
              </Row>
              <Row>
                <Col lg={24} md={24} sm={24} sx={24}>
                  <Form.Item
                    className="Formitem"
                    label={
                      <span style={{ color: "#7B8190", fontSize: "14px" }}>
                        Subscription Pack
                      </span>
                    }
                  >
                    {getFieldDecorator("subscriptionPack", {
                      rules: [
                        { required: true, message: "Please select pack!" },
                      ],
                    })(
                      <Select style={{ width: "100%" }}>
                        {this.state.subscriptionPacks
                          .filter(
                            (s) => s.userType === this.state.selectedUserProfile
                          )
                          .map((s) => (
                            <Option key={s.id} value={s.id}>
                              {s.price === 0 || s.price === "0"
                                ? "Free"
                                : s.price !== undefined && `$ ${s.price}/month`}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Modal>
        </Form>
      </div>
    );
  }
}
export default Form.create({ name: "addSubscription" })(withRouter(Users));
