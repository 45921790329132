import React, { Component } from "react";
import { withRouter } from "react-router";
import "antd/dist/antd.css";
import {
  Row,
  Spin,
  Col,
  Button,
  Form,
  Input,
  Card,
  Table,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import { list, add, update } from "../../api/Apis";
import AppConfigs from "../../Config/AppConfig";

class AddApiFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingStatus: false,
      dataLoadingStatus: true,
      dataType: "apiConfig",
      show: true,
      apiList: [],
      modalVisible: false,
      recordId: "",
    };
  }

  componentDidMount = () => {
    this.getApiDetails();
  };

  getApiDetails = () => {
    this.setState({
      dataLoadingStatus: true,
    });
    list(AppConfigs["entityNames"]["masterData"], {
      dataType: "apiConfig",
    }).then((result) => {
      if (result.success) {
        this.setState({
          apiList: result.data,
          dataLoadingStatus: false,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        console.log(result.errors);
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loadingStatus: true,
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let data = {
          entityData: {
            stateType: values["stateType"],
            vendorType: values["vendorType"],
            apiUrl: values["apiUrl"],
            stateName: values["stateName"],
            dataType: this.state.dataType,
            recordStatus: true,
            typeOfListing: values["typeOfListing"],
          },
        };
        if (this.state.recordId) {
          data["entityData"]["id"] = this.state.recordId;
        }
        add("masterData", data).then((result) => {
          if (result.success) {
            this.setState({
              modalVisible: false,
              loadingStatus: false,
            });
            this.getApiDetails();
          } else {
            this.setState({
              loadingStatus: true,
            });
            alert("Something went wrong!");
            console.log(result.errors);
          }
        });
      } else {
        this.setState({
          loadingStatus: false,
        });
      }
    });
  };

  handleApis = (record) => {
    let recordId = null;
    if (record) {
      this.props.form.setFieldsValue({
        stateType: record["stateType"],
        vendorType: record["vendorType"],
        apiUrl: record["apiUrl"],
        stateName: record["stateName"],
      });
      recordId = record["id"];
    } else {
      this.props.form.setFieldsValue({
        stateType: "",
        vendorType: "",
        apiUrl: "",
        stateName: "",
      });
    }
    this.setState({
      recordId,
      modalVisible: true,
    });
  };
  apiActive = (record) => {
    let data = {
      entityData: {
        isActive: !record.isActive,
        dataType: "apiConfig",
      },
    };
    update(AppConfigs["entityNames"]["masterData"], record.id, data)
      .then((res) => {
        console.log(res, ";;;;;;");
        if (res.success) {
          this.getApiDetails();
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  render() {
    const { modalVisible } = this.state;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "Api state name",
        dataIndex: "stateType",
        key: "stateType",
        show: true,
        render: (stateType) => {
          return <span>{stateType}</span>;
        },
      },
      {
        title: "Vendor",
        dataIndex: "vendorType",
        key: "vendorType",
        show: true,
        render: (vendorType) => {
          return <span>{vendorType}</span>;
        },
      },
      {
        title: "URL",
        dataIndex: "apiUrl",
        key: "apiUrl",
        show: true,
        render: (apiUrl) => {
          return <span>{apiUrl}</span>;
        },
      },
      {
        title: "Type of url",
        dataIndex: "typeOfListing",
        key: "typeOfListing",
        show: true,
        render: (typeOfListing) => {
          return <span>{typeOfListing}</span>;
        },
      },
      {
        title: "State Name",
        dataIndex: "stateName",
        key: "stateName",
        show: true,
        render: (stateName) => {
          return <span>{stateName}</span>;
        },
      },
      {
        title: "DETAILS",
        key: "id",
        dataIndex: "id",
        show: this.state.show,
        render: (id, record) => (
          <Dropdown
            overlay={menu(id, record)}
            placement="bottomRight"
            className="orders-dropdown"
          >
            <Button>...</Button>
          </Dropdown>
        ),
      },
    ];

    const menu = (id, record) => (
      <Menu>
        <Menu.Item className="blue">
          <span
            style={{ color: "rgba(0, 0, 0, 0.65)", cursor: "pointer" }}
            onClick={() => {
              this.handleApis(record);
            }}
          >
            Edit
          </span>
        </Menu.Item>
        <Menu.Item className="blue">
          <span
            style={{ color: "rgba(0, 0, 0, 0.65)", cursor: "pointer" }}
            onClick={() => {
              this.apiActive(record);
            }}
          >
            {record.isActive ? "Deactive" : "Active"}
          </span>
        </Menu.Item>
      </Menu>
    );
    return (
      <Spin spinning={this.state.loadingStatus}>
        <div className="registration">
          <div className="filter">
            <Row>
              <Col style={{ padding: "30px 30px 0px 30px", cursor: "pointer" }}>
                <h1 className="registrationtext">
                  Configurations &nbsp;&nbsp;
                  <i
                    className="fa fa-plus-circle"
                    onClick={() => {
                      this.handleApis(null);
                    }}
                    style={{ color: "#1890ff" }}
                  ></i>
                </h1>
              </Col>
              <Col style={{ padding: "0px 30px", cursor: "pointer" }}>
                <Table
                  columns={columns.filter((col) => col.show !== false)}
                  dataSource={this.state.apiList}
                  loading={this.state.dataLoadingStatus}
                  rowKey={(record) => record.id}
                  style={{ wordWrap: "break-word", wordBreak: "break-word" }}
                />
              </Col>
            </Row>
            <Modal
              // className="modal-black-close"
              visible={modalVisible}
              style={{ marginTop: "2%" }}
              onCancel={() =>
                this.setState({
                  modalVisible: false,
                })
              }
              footer={null}
            >
              <div>
                <Form onSubmit={this.handleSubmit} style={{ width: "100%" }}>
                  <Card title="Add API Details">
                    <Row gutter={10}>
                      <Col lg={24} md={24} sm={24} sx={24}>
                        <Form.Item
                          className="Formitem"
                          label={
                            <span
                              style={{ color: "#7B8190", fontSize: "14px" }}
                            >
                              State
                            </span>
                          }
                        >
                          {getFieldDecorator("stateType", {
                            rules: [
                              {
                                required: true,
                                message: "Please input state!",
                              },
                            ],
                          })(<Input />)}
                        </Form.Item>
                      </Col>
                      <Col lg={24} md={24} sm={24} sx={24}>
                        <Form.Item
                          className="Formitem"
                          label={
                            <span
                              style={{ color: "#7B8190", fontSize: "14px" }}
                            >
                              Vendor
                            </span>
                          }
                        >
                          {getFieldDecorator("vendorType", {
                            rules: [
                              {
                                required: true,
                                message: "Please input vendor!",
                              },
                            ],
                          })(<Input />)}
                        </Form.Item>
                      </Col>
                      <Col lg={24} md={24} sm={24} sx={24}>
                        <Form.Item
                          className="Formitem"
                          label={
                            <span
                              style={{ color: "#7B8190", fontSize: "14px" }}
                            >
                              URL
                            </span>
                          }
                        >
                          {getFieldDecorator("apiUrl", {
                            rules: [
                              { required: true, message: "Please input url!" },
                            ],
                          })(<Input />)}
                        </Form.Item>
                      </Col>
                      <Col lg={24} md={24} sm={24} sx={24}>
                        <Form.Item
                          className="Formitem"
                          label={
                            <span
                              style={{ color: "#7B8190", fontSize: "14px" }}
                            >
                              Type of url
                            </span>
                          }
                        >
                          {getFieldDecorator("typeOfListing", {
                            rules: [
                              {
                                required: true,
                                message: "Please input Type of listing!",
                              },
                            ],
                          })(<Input />)}
                        </Form.Item>
                      </Col>
                      <Col lg={24} md={24} sm={24} sx={24}>
                        <Form.Item
                          className="Formitem"
                          label={
                            <span
                              style={{ color: "#7B8190", fontSize: "14px" }}
                            >
                              State Name
                            </span>
                          }
                        >
                          {getFieldDecorator("stateName", {
                            rules: [
                              {
                                required: true,
                                message: "Please input state name!",
                              },
                            ],
                          })(<Input />)}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <Row>
                    <Form.Item style={{ marginTop: "5%" }}>
                      <Button
                        type="primary"
                        style={{
                          backgroundColor: "#25559517",
                          color: "#255595",
                          marginRight: "30px",
                          padding: "0 20px 0 20px",
                          border: "none",
                        }}
                        onClick={() => this.setState({ modalVisible: false })}
                      >
                        Cancel
                      </Button>

                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.loadingStatus}
                        style={{
                          float: "right",
                          backgroundColor: "#255595",
                          color: "white",
                          padding: "0 20px 0 20px",
                        }}
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </div>
            </Modal>
          </div>
        </div>
      </Spin>
    );
  }
}
export default Form.create({ name: "addApiConfig" })(withRouter(AddApiFields));
