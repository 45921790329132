import React, { Component } from "react";
import "antd/dist/antd.css";
import { withRouter } from "react-router";
import {
  Table,
  Tabs,
  Card,
  Row,
  Col,
  Button,
  Menu,
  Dropdown,
  Modal,
  Select,
  Divider,
  Collapse,
} from "antd";
import moment from "moment";
import { list, update } from "../../api/Apis";
const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

class MissedLeads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      activeTabKey: 1,
      referedAgentId: "",
      referedAgentName: "",
      selectedProperty: "",
      referedAgentMail: "",
      dataLoadingStatus: false,
      enableViewLead: false,
      enableAssignLead: false,

      pendingLeads: [],
      inProgressLeads: [],
      completedLeads: [],
      agentsList: [],
    };
  }

  componentDidMount = () => {
    this.getAllAgents();
    this.getAllLeads();
  };

  getAllAgents = async () => {
    list("users?userProfile=AG").then((result) => {
      if (result.success) {
        let singleAgent = result.data.map((user) => {
          return {
            value: user.id,
            label: user.firstName + " " + user.lastName,
            email: user.email,
            userData: user,
          };
        });
        this.setState({
          dataLoadingStatus: false,
          agentsList: singleAgent,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        alert("Something went wrong!");
        console.log(result.errors);
      }
    });
  };

  getAllLeads = () => {
    this.setState({
      dataLoadingStatus: true,
    });
    list(`getMissedLeads`).then((result) => {
      if (result.success) {
        let pendingList = [];
        let inProgressList = [];
        let completedList = [];

        result.data.filter((lead) => {
          if (lead.isAssigned == false) {
            pendingList.push(lead);
          } else if (
            lead.isAssigned == true &&
            lead.assignedStatus == "pending"
          ) {
            inProgressList.push(lead);
          } else {
            completedList.push(lead);
          }
        });

        this.setState({
          pendingLeads: pendingList,
          inProgressLeads: inProgressList,
          completedLeads: completedList,
          dataLoadingStatus: false,
        });
      } else {
        this.setState({
          dataLoadingStatus: false,
        });
        alert("Something went wrong!");
        console.log(result.errors);
      }
    });
  };

  assignLead = (property) => {
    this.setState({
      selectedProperty: property,
      enableAssignLead: true,
    });
  };

  onReferedBySelectAgent = (id) => {
    const selectedAgentDetails = this.state.agentsList.filter(
      (agent) => agent.value === id
    );

    this.setState({
      referedAgentName: selectedAgentDetails[0].label,
      referedAgentId: selectedAgentDetails[0].value,
      referedAgentMail: selectedAgentDetails[0].email,
    });
  };

  assignAgent = () => {
    const {
      referedAgentId,
      selectedProperty,
      referedAgentName,
      referedAgentMail,
    } = this.state;

    this.setState({
      dataLoadingStatus: true,
    });
    const reAssginTime = moment(selectedProperty.schedule_date_time).format();
    let submitData = {
      entityData: {
        propertyId: selectedProperty.propertyId,
        propertyTitle: selectedProperty.propertyTitle,
        propertyImgSrc: selectedProperty.propertyImgSrc,

        userName: selectedProperty.requestorDetails.name,
        userPhone: selectedProperty.requestorDetails.phone,
        userEmail: selectedProperty.requestorDetails.email,

        isRegistred: true,

        agentId: referedAgentId,
        agentName: referedAgentName,

        agentEmail: referedAgentMail,

        relatedTo: referedAgentId,
        stateType: selectedProperty.stateType,
      },
    };
    if (selectedProperty.notificationType === "schedule") {
      submitData["entityData"]["createdBy"] = "user";
      submitData["entityData"]["scheduleStatus"] = "P";
      submitData["entityData"]["scheduleDate"] = moment(
        selectedProperty.schedule_date_time
      ).unix();
      submitData["entityData"]["scheduleTime"] = moment(reAssginTime).unix();
      submitData["entityData"]["formatedDateAndTime"] =
        selectedProperty.schedule_date_time;
      submitData["entityData"]["message"] = selectedProperty.message;
      submitData["entityData"]["scheduleHistory"] = [];
      submitData["entityData"]["typeOfSchedule"] = "listingSchedule";
      submitData["entityData"]["notificationType"] =
        selectedProperty.notificationType;
    } else if (selectedProperty.notificationType === "question") {
      submitData["entityData"]["message"] = selectedProperty.message;
      submitData["entityData"]["notificationType"] =
        selectedProperty.notificationType;
    } else {
      submitData["entityData"]["message"] = selectedProperty.message;
      submitData["entityData"]["notificationType"] =
        selectedProperty.notificationType;
    }
    update("assignProperty", selectedProperty.id, submitData).then((result) => {
      if (result.success) {
        this.resetValues();
        this.getAllLeads();
      } else {
        this.resetValues();
        alert("Something went wrong!");
        console.log(result.errors);
      }
    });
  };

  resetValues = () => {
    this.setState({
      referedAgentId: "",
      referedAgentName: "",
      selectedProperty: "",
      referedAgentMail: "",
      enableViewLead: false,
      enableAssignLead: false,
      dataLoadingStatus: false,
    });
  };

  leadStatus = () => {
    return (
      <span
        style={{
          textTransform: "capitalize",
          backgroundColor:
            this.state.activeTabKey == "1"
              ? "#f05150"
              : this.state.activeTabKey == "2"
              ? "#5bc0de"
              : "#27c24c",
          fontWeight: "bold",
          padding: "5px 10px",
          color: "#fff",
        }}
      >
        {this.state.activeTabKey == "1"
          ? "Un-Assigned"
          : this.state.activeTabKey == "2"
          ? "Assigned"
          : "Completed"}
      </span>
    );
  };

  selectedPropertyInfo = () => {
    const { selectedProperty } = this.state;
    return (
      <div style={{ backgroundColor: "#f9fafb", padding: "20px 20px 30px" }}>
        <Row gutter={[24, 24]}>
          <Col span={24} style={{ padding: "20px 20px 0px 20px" }}>
            <h4 style={{ color: "#aaa" }}>Property title :</h4>

            <a
              target="_blank"
              href={`${process.env.REACT_APP_BASE_URL}homeDetails/${selectedProperty.stateType}/${selectedProperty.propertyId}`}
              rel="noreferrer"
            >
              <h2
                style={{
                  cursor: "pointer",
                  color: "#3045d2",
                  textDecoration: "underline",
                }}
              >
                {selectedProperty.propertyTitle}
              </h2>
            </a>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={24} style={{ padding: "20px" }}>
            <h4 style={{ color: "#aaa" }}>Message :</h4>
            <h3>{selectedProperty.message}</h3>
          </Col>
        </Row>

        {selectedProperty.notificationType === "schedule" && (
          <Row gutter={[24, 24]}>
            <Col span={24} style={{ padding: "20px" }}>
              <h4 style={{ color: "#aaa" }}>Schedule date {"&"} time :</h4>
              <h3>{selectedProperty.schedule_date_time}</h3>
            </Col>
          </Row>
        )}
        <Row gutter={[24, 24]}>
          <Col span={12} style={{ padding: "10px 10px 10px 20px" }}>
            {selectedProperty && selectedProperty.requestorDetails && (
              <Card>
                <Row>
                  <h4 style={{ color: "#aaa" }}>Requester Name</h4>
                  <h3>
                    {selectedProperty.requestorDetails.name !== "" &&
                    selectedProperty.requestorDetails.name !== null &&
                    selectedProperty.requestorDetails.name !== undefined
                      ? selectedProperty.requestorDetails.name
                      : "N/A"}
                  </h3>
                </Row>
                <Row>
                  <h4 style={{ color: "#aaa" }}>Requester E-Mail</h4>
                  <h3>
                    {selectedProperty.requestorDetails.email !== "" &&
                    selectedProperty.requestorDetails.email !== null &&
                    selectedProperty.requestorDetails.email !== undefined
                      ? selectedProperty.requestorDetails.email
                      : "N/A"}
                  </h3>
                </Row>
                <Row>
                  <h4 style={{ color: "#aaa" }}>Requester Phone</h4>
                  <h3>
                    {selectedProperty.requestorDetails.phone !== "" &&
                    selectedProperty.requestorDetails.phone !== null &&
                    selectedProperty.requestorDetails.phone !== undefined
                      ? selectedProperty.requestorDetails.phone
                      : "N/A"}
                  </h3>
                </Row>
              </Card>
            )}
          </Col>
          <Col span={12} style={{ padding: "10px 20px 10px 10px" }}>
            {selectedProperty && selectedProperty.agentDetails && (
              <Card>
                <Row>
                  <h4 style={{ color: "#aaa" }}>Requested Date</h4>
                  <h3>
                    {moment(selectedProperty.createdAt).format(
                      "MM-DD-YYYY H:M a"
                    )}
                  </h3>
                </Row>
                <Row>
                  <h4 style={{ color: "#aaa" }}>Listed Agent Name</h4>
                  <h3>
                    {selectedProperty.agentDetails.name !== "" &&
                    selectedProperty.agentDetails.name !== null &&
                    selectedProperty.agentDetails.name !== undefined
                      ? selectedProperty.agentDetails.name
                      : "N/A"}
                  </h3>
                </Row>
                <Row>
                  <h4 style={{ color: "#aaa" }}>Listed Agent E-Mail</h4>
                  <h3>
                    {selectedProperty.agentDetails.email !== "" &&
                    selectedProperty.agentDetails.email !== null &&
                    selectedProperty.agentDetails.email !== undefined
                      ? selectedProperty.agentDetails.email
                      : "N/A"}
                  </h3>
                </Row>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  agentsListView = () => {
    const { agentsList, referedAgentName } = this.state;
    return (
      <Row>
        <h4 style={{ color: "#aaa", marginTop: "20px" }}>
          Assign to the Agent
        </h4>
        <Select
          style={{ width: "100%" }}
          className="input-width"
          showSearch
          optionFilterProp="children"
          options={agentsList}
          onSelect={(e) => this.onReferedBySelectAgent(e)}
          filterOption={(inputValue, option) =>
            option.props.children
              .toLowerCase()
              .indexOf(inputValue.toLowerCase()) !== -1
          }
          value={referedAgentName}
        >
          {agentsList.map((agent) => (
            <Option key={agent.value} value={agent.value}>
              {agent.label}
            </Option>
          ))}
        </Select>
      </Row>
    );
  };

  resultView = (type, icon, color, title, result) => {
    return (
      <Card className="mob-card" hoverable>
        <Row justify="space-around">
          <Col
            span={8}
            style={{
              backgroundColor: type == "ads" ? "#dce3e9" : "#ecf0f3",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            <i
              class={`fa ${icon}`}
              style={{
                fontSize: "80px",
                color: color,
              }}
            ></i>
          </Col>
          <Col span={14} offset={2}>
            <div>
              <h4 className={type == "ads" ? "mid-title" : "sub-title"}>
                {title}
              </h4>
              <h1 className="result-small">{result}</h1>
            </div>
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const columns = [
      {
        title: "Lead Type",
        dataIndex: "notificationType",
        key: "notificationType",
        render: (notificationType) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color:
                  notificationType === "schedule"
                    ? "#3045d2"
                    : notificationType === "question"
                    ? "#f7d131"
                    : "#3abe15",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {notificationType === "schedule"
                ? "Request a Showing"
                : notificationType === "question"
                ? "Q & A"
                : "Buyer Interest"}
            </span>
          );
        },
      },
      {
        title: "Property Title",
        dataIndex: "propertyTitle",
        key: "propertyTitle",
        render: (propertyTitle) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {propertyTitle}
            </span>
          );
        },
      },
      {
        title: "Agent",
        dataIndex: "agentDetails",
        key: "agentDetails",
        render: (agentDetails) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {agentDetails && agentDetails.name ? agentDetails.name : ""}
            </span>
          );
        },
      },
      {
        title: "Joined HLB",
        dataIndex: "isRegistered",
        key: "isRegistered",
        render: (isRegistered, record) => {
          return (
            <>
              <div
                style={{
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  padding: "2px 5px",
                  marginLeft: "20px",
                }}
              >
                {isRegistered ? "Yes" : "No"}
              </div>
              <div>
                {isRegistered &&
                  record &&
                  record.userDetails &&
                  record.userDetails.id && (
                    <>
                      <a
                        target="_blank"
                        href={`${process.env.REACT_APP_BASE_URL}agent/viewProfile/${record.userDetails.id}`}
                      >
                        &nbsp; View profile
                      </a>
                    </>
                  )}
              </div>
            </>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "isAssigned",
        key: "isAssigned",
        render: this.leadStatus,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt) => {
          return <span>{moment(createdAt).format("MM-DD-YYYY H:M a")}</span>;
        },
      },
      {
        title: "Details",
        key: "id",
        dataIndex: "id",
        show: this.state.show,
        render: (id, record) => (
          <Dropdown
            overlay={menu(id, record)}
            placement="bottomRight"
            className="orders-dropdown"
          >
            <Button>...</Button>
          </Dropdown>
        ),
      },
    ];
    const assignAndCompletedcolumns = [
      {
        title: "Lead Type",
        dataIndex: "notificationType",
        key: "notificationType",
        render: (notificationType) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                color:
                  notificationType === "schedule"
                    ? "#3045d2"
                    : notificationType === "question"
                    ? "#f7d131"
                    : "#3abe15",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {notificationType === "schedule"
                ? "Request a Showing"
                : notificationType === "question"
                ? "Q & A"
                : "Buyer Interest"}
            </span>
          );
        },
      },
      {
        title: "Property Title",
        dataIndex: "propertyTitle",
        key: "propertyTitle",
        render: (propertyTitle) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {propertyTitle}
            </span>
          );
        },
      },
      {
        title: "Re-assigned Agent",
        dataIndex: "agentDetails",
        key: "agentDetails",
        render: (agentDetails) => {
          return (
            <span
              style={{
                textTransform: "capitalize",
                fontWeight: "bold",
                padding: "2px 5px",
              }}
            >
              {agentDetails && agentDetails.name ? agentDetails.name : ""}
            </span>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "isAssigned",
        key: "isAssigned",
        render: this.leadStatus,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt) => {
          return <span>{moment(createdAt).format("MM-DD-YYYY H:M a")}</span>;
        },
      },
      {
        title: "Details",
        key: "id",
        dataIndex: "id",
        show: this.state.show,
        render: (id, record) => (
          <Dropdown
            overlay={menu(id, record)}
            placement="bottomRight"
            className="orders-dropdown"
          >
            <Button>...</Button>
          </Dropdown>
        ),
      },
    ];
    const menu = (id, record) => (
      <Menu>
        <Menu.Item className="blue">
          <span
            style={{ color: "rgba(0, 0, 0, 0.65)", cursor: "pointer" }}
            onClick={() => {
              this.setState({
                selectedProperty: record,
                enableViewLead: true,
              });
            }}
          >
            View Lead
          </span>
        </Menu.Item>

        {this.state.activeTabKey == "1" && (
          <Menu.Item className="blue">
            <span
              style={{ color: "rgba(0, 0, 0, 0.65)", cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  selectedProperty: record,
                  enableAssignLead: true,
                });
              }}
            >
              Assign Lead
            </span>
          </Menu.Item>
        )}
      </Menu>
    );

    const {
      dataLoadingStatus,
      enableViewLead,
      enableAssignLead,
      pendingLeads,
      inProgressLeads,
      completedLeads,
      selectedProperty,
    } = this.state;
    return (
      <div>
        <Collapse style={{ margin: "12px" }}>
          <Panel header="Lead Analytics" key="1">
            <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
              <Col span={6}>
                {this.resultView(
                  "leads",
                  "fa-sitemap",
                  "#000",
                  "Total",
                  pendingLeads.length +
                    inProgressLeads.length +
                    completedLeads.length
                )}
              </Col>
              <Col span={6}>
                {this.resultView(
                  "leads",
                  "fa-sitemap",
                  "#000",
                  "Un-Assigned",
                  pendingLeads.length
                )}
              </Col>
              <Col span={6}>
                {this.resultView(
                  "leads",
                  "fa-sitemap",
                  "#000",
                  "Assigned",
                  inProgressLeads.length
                )}
              </Col>
              <Col span={6}>
                {this.resultView(
                  "leads",
                  "fa-sitemap",
                  "#000",
                  "Completed",
                  completedLeads.length
                )}
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Row style={{ padding: "10px", marginTop: "15px" }}>
          <Tabs
            defaultActiveKey="1"
            type="card"
            onChange={(key) =>
              this.setState({
                activeTabKey: key,
              })
            }
          >
            <TabPane key="1" tab="Un-Assigned Leads">
              <div style={{ backgroundColor: "#FFF" }}>
                <Table
                  columns={columns}
                  dataSource={pendingLeads}
                  loading={dataLoadingStatus}
                  rowKey={(record) => record.id}
                />
              </div>
            </TabPane>

            <TabPane key="2" tab="Assigned Leads">
              <div style={{ backgroundColor: "#FFF" }}>
                <Table
                  columns={assignAndCompletedcolumns}
                  dataSource={inProgressLeads}
                  loading={dataLoadingStatus}
                  rowKey={(record) => record.id}
                />
              </div>
            </TabPane>

            <TabPane key="3" tab="Completed Leads">
              <div style={{ backgroundColor: "#FFF" }}>
                <Table
                  columns={assignAndCompletedcolumns}
                  dataSource={completedLeads}
                  loading={dataLoadingStatus}
                  rowKey={(record) => record.id}
                />
              </div>
            </TabPane>
          </Tabs>

          <Modal
            visible={enableAssignLead}
            title="Assign Lead to an Agent"
            width="80vw"
            style={{ marginTop: "2%" }}
            onCancel={() => this.resetValues()}
            footer={null}
          >
            {this.selectedPropertyInfo()}
            {this.agentsListView()}

            <Row>
              <Divider></Divider>
            </Row>

            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Button
                  type="default"
                  style={{ width: "100%" }}
                  onClick={() => {
                    this.resetValues();
                  }}
                >
                  Cancel
                </Button>
              </Col>

              <Col span={12}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => this.assignAgent()}
                >
                  Assign
                </Button>
              </Col>
            </Row>
          </Modal>

          <Modal
            visible={enableViewLead}
            title="View Lead"
            width="80vw"
            style={{ marginTop: "2%" }}
            onCancel={() => this.resetValues()}
            footer={null}
          >
            {this.selectedPropertyInfo()}

            {selectedProperty && selectedProperty.userDetails && (
              <div
                style={{ backgroundColor: "#f9fafb", padding: "0px 20px 20px" }}
              >
                <Row gutter={[24, 24]}>
                  <Col span={12} style={{ padding: "20px 10px 20px 20px" }}>
                    <Card>
                      {selectedProperty && selectedProperty.updatedAt && (
                        <Row>
                          <h4 style={{ color: "#aaa" }}>Re-Assigned Date</h4>
                          <h3>
                            {selectedProperty.updatedAt !== "" &&
                            selectedProperty.updatedAt !== null &&
                            selectedProperty.updatedAt !== undefined
                              ? moment(selectedProperty.updatedAt).format(
                                  "MM-DD-YYYY H:M a"
                                )
                              : "N/A"}
                          </h3>
                        </Row>
                      )}

                      <Row>
                        <h4 style={{ color: "#aaa" }}>
                          Re-Assigned Agent Name
                        </h4>
                        <h3>
                          {selectedProperty.userDetails.firstName !== "" &&
                          selectedProperty.userDetails.firstName !== null &&
                          selectedProperty.userDetails.firstName !== undefined
                            ? selectedProperty.userDetails.firstName +
                              " " +
                              selectedProperty.userDetails.lastName
                            : "N/A"}
                        </h3>
                      </Row>
                    </Card>
                  </Col>

                  <Col span={12} style={{ padding: "20px 20px 20px 10px" }}>
                    <Card>
                      <Row>
                        <h4 style={{ color: "#aaa" }}>
                          Re-Assigned Agent E-Mail
                        </h4>
                        <h3>
                          {selectedProperty.userDetails.email !== "" &&
                          selectedProperty.userDetails.email !== null &&
                          selectedProperty.userDetails.email !== undefined
                            ? selectedProperty.userDetails.email
                            : "N/A"}
                        </h3>
                      </Row>
                      <Row>
                        <h4 style={{ color: "#aaa" }}>
                          Re-Assigned Agent Phone
                        </h4>
                        <h3>
                          {selectedProperty.userDetails.phone !== "" &&
                          selectedProperty.userDetails.phone !== null &&
                          selectedProperty.userDetails.phone !== undefined
                            ? selectedProperty.userDetails.phone
                            : "N/A"}
                        </h3>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </Modal>
        </Row>
      </div>
    );
  }
}
export default withRouter(MissedLeads);
