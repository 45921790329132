import React, { Component } from 'react';
import {  withRouter } from 'react-router-dom';
import 'antd/dist/antd.css';
import Application from  './Containers/Application';
import { Route, Switch } from 'react-router-dom';
import './Stylesheet/admin.scss'
import Login from './Containers/Login';
class App extends Component {
  
  render() {   
    return (
     <div className="admin">
      <Switch>
        <Route path='/application' component={ Application } />
        <Route path='/' component={ Login } />
      </Switch>
     </div>
      
    );
  }
}
export default (withRouter(App));

