import { Call, ApiUrl, noAuthApiUrl, ParseGeneralResponse } from './ApiUtils';

const queryString = require('query-string');

export function noAuthList(entityName,query={}) {
    let url = noAuthApiUrl(entityName);
    url = queryString.stringifyUrl({url: url, query: query});
    
    return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}
export function list(entityName,query={}) {
    let url = ApiUrl(entityName);
    url = queryString.stringifyUrl({url: url, query: query});
    
    return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}

export function getDetails(entityName,id) {
    const url = ApiUrl(entityName+'/'+id);
    return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}

export function noAuthGetDetails(entityName,id) {
    const url = noAuthApiUrl(entityName+'/'+id);
    return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}

export function add(entityName,data) {
    const url = ApiUrl(entityName);;
    return Call('post', url, data, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}

export function update(entityName,id,data) {
    const url = ApiUrl(entityName+'/'+id);
    return Call('put', url, data, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}

export function deleteRecord(entityName,id) {
    const url = ApiUrl(entityName+'/'+id);
    return Call('delete', url, {}, ParseGeneralResponse, { responseDataKeys: { data: '' } }, true);
}