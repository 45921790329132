import React, { Component } from "react";
import "antd/dist/antd.css";
import { Form, Icon, Input, Button, Row } from "antd";
import Amplify, { Auth } from "aws-amplify";
import awsConfigure from "../Config/AwsConfig";
import Logo from "../Images/logo.png";
import { getDetails } from "../api/Apis";
import AppConfigs from "../Config/AppConfig";
Amplify.configure(awsConfigure);

class Login extends Component {
  state = {
    errors: "",
    visible: false,
    loading: false,
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.doLogin();
      }
    });
  };

  doLogin = async () => {
    try {
      this.setState({
        loading: true,
      });
      const user = await Auth.signIn(
        this.props.form.getFieldValue("username"),
        this.props.form.getFieldValue("password")
      );
      const userDetails = Object.assign(
        {},
        {
          token: user.signInUserSession.idToken.jwtToken,
          userid: user.username,
        }
      );
      this.getProfile(userDetails.userid);
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        var code = prompt("Please enter the code which is sent to your mail");
        if (code !== "") {
          this.confirmUser(this.props.form.getFieldValue("username"), code);
        }
      } else if (err.code === "PasswordResetRequiredException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
      } else if (err.code === "NotAuthorizedException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
      } else if (err.code === "UserNotFoundException") {
        this.setState({
          loading: false,
          errors: err.message,
        });
      } else {
        this.setState({
          loading: false,
          errors: err.message,
        });
      }
    }
  };

  getProfile = (id) => {
    this.setState({
      loading: true,
    });
    getDetails(AppConfigs["entityNames"]["users"], id).then((result) => {
      if (result.success) {
        localStorage.setItem("userDetails", JSON.stringify(result.data));
        if (result.data.userProfile !== "A") {
          this.setState({
            loading: false,
            errors: "Invalid user",
          });
          return;
        } else {
          this.props.history.push("/application/dashboard");
        }
      } else {
        console.log(result.errors);
        this.setState({
          loading: false,
          errors: result.errors.toString(),
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="admin-login-form">
        <div className="login-fields">
          <Row>
            <div style={{ textAlign: "center" }}>
              <img src={Logo} alt="Logo" style={{ width: "50%" }} />
            </div>
          </Row>
          <Form onSubmit={this.handleSubmit}>
            <Form.Item label={<label>Email</label>}>
              {getFieldDecorator("username", {
                rules: [
                  { required: true, message: "Please input valid email" },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Username"
                />
              )}
            </Form.Item>
            <Form.Item label="Password">
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please input your Password!" },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>

            {this.state.errors && (
              <div style={{ textAlign: "center" }}>
                <span style={{ color: "#f5222d" }}>{this.state.errors}</span>
              </div>
            )}

            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
              className="login-form-button"
              style={{
                width: "100%",
                backgroundColor: "#fc4e1a",
                borderColor: "#fc4e1a",
                marginTop: "40px",
              }}
            >
              Log In
            </Button>

            <h4
              style={{ textAlign: "center", marginTop: "5px", color: "black" }}
            >
              Version 5.05.11.02
            </h4>
          </Form>
        </div>
      </div>
    );
  }
}
export default Form.create({ name: "login" })(Login);
