import axios from 'axios/index';
import _ from 'lodash';
// import swal from 'sweetalert';
import AppConfig from '../Config/AppConfig';
import { Auth } from "aws-amplify";

export function PageParams(page, perPage) {
  return `page=${page || 1}&per_page=${perPage || AppConfig.perPage}`;
}

export function ApiUrl(path) {
  return `${AppConfig.API_GATEWAY}/api/${path}`;
}

export function noAuthApiUrl(path) {
  return `${AppConfig.API_GATEWAY}/noAuth/${path}`;
}

export function HttpErrorMessage(error) {
  const status = error.response ? error.response.status : '';
  if (_.get(error, 'response.data.errors', null)) {
    return {
      message: error.response.data.errors,
      code: status,
    };
  } else if (_.get(error, 'response.data', null)) {
    return {
      message: error.response.data,
      code: status,
    };
  }
  return [error.message, status];
}

export function ParseGeneralResponse(success, response, options) {
  const result = { success };
  if (!_.isEmpty(options.return)) {
    result.return = options.return;
  }
  if (success) {
    const dataKeys = options.responseDataKeys || {};
    _.forEach(dataKeys, (value, key) => {
      const valueKey = _.isEmpty(value) ? 'data' : `data.${value}`;
      result[key] = _.get(response, valueKey, null);
    });
  } else {
    result.errors = HttpErrorMessage(response);
  }
  return result;
}

export async function Call(method, url, data, parseCallback, options = {}, authorization) {
  console.log(method, 'method');
  const dataObj = {
    method,
    url,
    data,
  };
  if (authorization) {
    // const { token } = JSON.parse(utils.getCookie('user'));
    let token;
    await Auth.currentSession()
      .then((response) => {
        token = response.idToken.jwtToken;
      })
      .catch((error) => {
          console.log(error);
      });
    dataObj.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return new Promise(resolve => {
    axios(dataObj)
      .then(response => {
        resolve(parseCallback(true, response, options));
      })
      .catch(async error => {
        console.log(error, 'Error');
        if (error === 'Network Error') {
          // swal({
          //   text: 'Network Error. Please check your net connection!',
          //   icon: 'error',
          //   button: 'Ok',
          // });
          alert("Network Error. Please check your net connection!");
        } else
        if (error.response.status === 401) {
          // await utils.setCookie('user', '', false);
          // swal({
          //   text: 'Your session has expired.Please login!',
          //   icon: 'error',
          //   button: 'Ok',
          // })
          //   .then(() => {
          //     window.location.href = '/login';
          //   });
          alert("Your session has expired.Please login!");
          window.location.href = '/login';
        } else if (error.response.status === 400) {
          // swal({
          //   text: error.response.data["message"],
          //   icon: 'error',
          //   button: 'Ok',
          // })
          alert(error.response.data["message"]);
        }
        resolve(parseCallback(false, error, options));
      });
  });
}
