import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button, Form, Input, Card, Select, Popover, message } from 'antd';
import { Link } from 'react-router-dom';
import { add } from '../../api/Apis';
import AppConfigs from '../../Config/AppConfig';
import AwsConfig from '../../Config/AwsConfig';
import Amplify, { Auth } from 'aws-amplify';

// import { formatPhoneNumber } from '../commonFunctions';
Amplify.configure(AwsConfig);
const { Option } = Select;

const Password = {

    _pattern: /^[A-Za-z0-9_@./#&+-]*$/,
    _getRandomByte() {
      let result;
      if (window.crypto && window.crypto.getRandomValues) {
        result = new Uint8Array(1);
        window.crypto.getRandomValues(result);
        return result[0];
      } else if (window.msCrypto && window.msCrypto.getRandomValues) {
        result = new Uint8Array(1);
        window.msCrypto.getRandomValues(result);
        return result[0];
      } else {
        return Math.floor(Math.random() * 256);
      }
    },

    generate(length) {
      let i = 0;
      const password = [];
      while (i < length) {
        let characterValid = false;
        while (!characterValid) {
          const result = String.fromCharCode(this._getRandomByte());
          if (this._pattern.test(result)) {
            password.push(result);
            characterValid = true;
          }
        }
        i += 1;
      }
      return password.join('');
    },
};

class UserOperations extends Component {
    state = {
        userDetails: {},
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    loading: true
                })
                let data = {
                    "entityData":{
                        "firstName": values["firstName"],
                        "lastName": values["lastName"],
                        "email": values["email"],
                        "phone": values["phone"],
                        "userProfile":values["userProfile"],
                        "recordStatus": true,
                        "isActive":false
                    }
                }
                let userName = values["email"];
                let password = values["password"];
                this.addUser(userName,password,data);
            }
        });
    }

    addUser = async (email,password,regData) => {
        await Auth.signUp({
          username: email,
          password: password,
        })
        .then(data => {
            regData["entityData"]["id"] = data.userSub
            add(AppConfigs["entityNames"]["users"], regData)
                .then(result => {
                    if (result.success) {
                        message.success(`User created successfully.`);
                        this.props.history.push('/application/users');
                    } else {
                        console.log(result.errors);
                    }
                });
        })
        .catch(err => {
            if (err.code === 'InvalidPasswordException') {
                alert('Invalid Password: \n- Minimum password length:6\n- Require at least one uppercase letter\n- Require at least one lowercase letter\n- Require at least one number\n- Require at least one nonalphanumeric character');
                this.setState({ value: true, loading: false });
            } else {
                alert(err.message);
                this.setState({ value: true, loading: false });
            }
        });
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        let passwordCriteria = (
            <div>
              <Row>
                <Col lg={24} md={24} sm={24} xs={24}>
                  -Minimum password length: 6 <br/>
                  -Required at least one uppercase letter<br/>
                  -Required at least one lowercase letter<br/>
                  -Required at least one number<br/>
                  -Required at least one nonalphanumeric character<br/>
                </Col>
              </Row>
            </div>
        )
        return (
            <div style={{ position: 'absolute', top: '143px', paddingLeft: '25%', paddingRight: '25%' }}>
                <Col lg={12} md={12} sm={24} xs={24} offset={6}>
                    <div className="site-card-border-less-wrapper">
                        <Form onSubmit={this.handleSubmit}  >
                            <Card title="Add User" style={{ borderRadius: '10px' }}>
                                <Row gutter={10}>
                                <Col lg={24} md={24} sm={24} sx={24}>
                                    <Form.Item className="Formitem"
                                        label={<span style={{ color: '#7B8190', fontSize: '14px' }}>First Name</span>}>
                                        {getFieldDecorator('firstName', {
                                            rules: [{ required: true, message: 'Please input First Name!' }],
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col lg={24} md={24} sm={24} sx={24}>
                                    <Form.Item className="Formitem"
                                        label={<span style={{ color: '#7B8190', fontSize: '14px' }}>Last Name</span>}>
                                        {getFieldDecorator('lastName', {
                                            rules: [{ required: true, message: 'Please input Last Name!' }],
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col lg={24} md={24} sm={24} sx={24}>
                                    <Form.Item className="Formitem"
                                        label={<span style={{ color: '#7B8190', fontSize: '14px' }}>Email</span>}>
                                        {getFieldDecorator('email', {
                                            rules: [{ required: false, type: 'email', message: 'Please input Email!' }],
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col lg={24} md={24} sm={24} sx={24}>
                                    <Form.Item className="Formitem"
                                        label={<span style={{ color: '#7B8190', fontSize: '14px' }}>Phone</span>} >
                                        {getFieldDecorator('phone', {
                                            rules: [{ required: true, message: 'Please input Phone!' }],
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col lg={24} md={24} sm={24} sx={24}>
                                <Form.Item
                                    className="Formitem"
                                    label={
                                        <span style={{ color: "#7B8190", fontSize: "14px" }}>
                                        Profile
                                        </span>
                                    }
                                    >
                                    {getFieldDecorator("userProfile", {
                                        rules: [
                                        {
                                            required: true,
                                            message: "Please select user profile!",
                                        },
                                        ],
                                    })(
                                        <Select>
                                        {/* <Option key="A" value="A">Admin</Option> */}
                                        <Option key="AG" value="AG">
                                            Agent
                                        </Option>
                                        <Option key="B" value="B">
                                            Buyer
                                        </Option>
                                        <Option key="S" value="S">
                                            Seller
                                        </Option>
                                        <Option key="L" value="L">
                                            Lender
                                        </Option>
                                        <Option key="V" value="V">
                                            Vendor
                                        </Option>
                                        </Select>
                                    )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="Formitem"
                                        label={<span className="label">Password</span>}
                                    >
                                    {getFieldDecorator("password", {
                                        rules: [
                                        {
                                            required: true,
                                            message: "Please input your password!",
                                        },
                                        { validator: this.validateToNextPassword },
                                        ],
                                    })(
                                        <Input.Password
                                        placeholder="*********"
                                        autoComplete="new-password"
                                        className="form_input_height"
                                        />
                                    )}
                                    </Form.Item>
                                    <Popover
                                    placement="top"
                                    title={
                                        <h2 style={{ textAlign: "center" }}>
                                        Password Criteria
                                        </h2>
                                    }
                                    content={passwordCriteria}
                                    trigger="hover"
                                    >
                                    <span style={{ color: "#20a8d8" }}>Criteria</span>
                                    </Popover>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="Formitem"
                                        label={<span className="label">Confirm Password</span>}
                                    >
                                    {getFieldDecorator("confirm", {
                                        rules: [
                                        {
                                            required: true,
                                            message: "Please confirm your password!",
                                        },
                                        { validator: this.compareToFirstPassword },
                                        ],
                                    })(
                                        <Input.Password
                                        placeholder="*********"
                                        onBlur={this.handleConfirmBlur}
                                        className="form_input_height"
                                        />
                                    )}
                                    </Form.Item>
                                </Col>
                           
                                </Row>
                            </Card>
                            <Row>
                                <Form.Item style={{ marginTop: '5%' }}>
                                    <Button type="primary"   style={{ backgroundColor: '#25559517', color: '#255595', marginRight: '30px', padding: '0 20px 0 20px', border: 'none' }}>
                                        <Link to="/application/users">
                                            Cancel
                                            </Link>
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={this.state.loading} onClick={this.handleSubmit} style={{ float: 'right', backgroundColor: '#255595', color: 'white', padding: '0 20px 0 20px' }}   >
                                        Save
                                        </Button>
                                </Form.Item>
                            </Row>

                        </Form>
                    </div>
                </Col>
            </div>
            // <div>
            //     <Form className='form-border' onSubmit={this.addUser}>
            //         <Row className='add-lead-button-margin' style={{ marginTop: '20px' }}>
            //             <Col span={16} />
            //             <Col span={2} offset={1} className='add-lead-cancel'>
            //                 <Button onClick={() => { this.props.closeForm(); }}>Cancel</Button>
            //             </Col>
            //             <Col span={2} className='add-lead-save'>
            //                 <Button type="primary" htmlType='submit' loading={this.state.loading}>Save</Button>
            //             </Col>
            //         </Row>
            //         <Row>
            //             <Row>
            //                 <Col span={9} offset={1}>
            //                     <div
            //                         style={{
            //                             margin: '20px 0px 20px 0px',
            //                             borderRadius: 5,
            //                         }}
            //                         className='add-lead border-form-item'
            //                     >
            //                         <Form.Item
            //                             label='First Name'
            //                         >
            //                             <Input label='firstName' id='firstName' value={this.state.userDetails.firstName} required onChange={e => this.handleOnChange(e)} />
            //                         </Form.Item>
            //                     </div>
            //                 </Col>
            //                 <Col span={9} offset={1}>
            //                     <div
            //                         style={{
            //                             margin: '20px 0px 20px 0px',
            //                             borderRadius: 5,
            //                         }}
            //                         className='add-lead border-form-item'
            //                     >
            //                         <Form.Item
            //                             label='Last Name'
            //                         >
            //                             <Input label='lastName' id='lastName' value={this.state.userDetails.lastName} required onChange={e => this.handleOnChange(e)} />
            //                         </Form.Item>
            //                     </div>
            //                 </Col>
            //             </Row>
            //             <Row>
            //                 <Col span={9} offset={1}>
            //                     <div
            //                         style={{
            //                             margin: '20px 0px 20px 0px',
            //                             borderRadius: 5,
            //                         }}
            //                         className='add-lead border-form-item'
            //                     >
            //                         <Form.Item
            //                             label='Email'
            //                         >
            //                             <Input label='Email' id='email' autoComplete="off" value={this.state.userDetails.email} required onChange={e => this.handleOnChange(e)} />
            //                         </Form.Item>
            //                     </div>
            //                 </Col>
            //                 <Col span={9} offset={1}>
            //                     <div
            //                         style={{
            //                             margin: '20px 0px 20px 0px',
            //                             borderRadius: 5,
            //                         }}
            //                         className='add-lead border-form-item'
            //                     >
            //                         <Form.Item
            //                             label='Password'
            //                         >
            //                             <Input type='password' label='password' autoComplete="new-password" id='password' value={this.state.userDetails.password} required onChange={e => this.handleOnChange(e)} />
            //                         </Form.Item>
            //                     </div>
            //                 </Col>
            //             </Row>
            //             <Row>
            //                 <Col span={9} offset={1}>
            //                     <div
            //                         style={{
            //                             margin: '20px 0px 20px 0px',
            //                             borderRadius: 5,
            //                         }}
            //                         className='add-lead border-form-item'
            //                     >
            //                         <Form.Item
            //                             label='Contact Phone'
            //                         >
            //                             <Input label='Contact Phone' id='phone' value={this.state.userDetails.phone} required onChange={e => this.handleOnChange(e)} />
            //                         </Form.Item>
            //                     </div>
            //                 </Col>
            //             </Row>

            //         </Row>
            //     </Form>
            // </div>
        
        );
    }
}
export default Form.create({ name: 'adduser' })(withRouter(UserOperations));
// export default UserOperations;
