import React, { Component } from "react";
import "antd/dist/antd.css";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb } from "antd";
import { Auth } from "aws-amplify";
import Logo from "../Images/logo.png";
import LogoMini from "../Images/logomini.png";
import AppRoutes from "../Config/AppRoutes";
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class Application extends Component {
  rootSubmenuKeys = ["sub1", "sub2", "sub4"];

  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  onLogout = async () =>
    new Promise((resolve) => {
      Auth.signOut();
      localStorage.clear();
      this.props.history.push("/login");
    });

  render() {
    const { collapsed } = this.state;
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          className="sidebarMenu"
        >
          <div style={{borderBottom:"1px solid #002140"}}>
            <img
              src={collapsed ? LogoMini : Logo}
              alt="Logo"
              width={collapsed ? "90" : "200px"}
              style={{ padding: "25px" }}
            />
          </div>
          <Menu
            theme="dark"
            inlineCollapsed={this.state.collapsed}
            defaultSelectedKeys={["1"]}
            mode="inline"
            defaultSelectedKeys={["sub1"]}
            defaultOpenKeys={["sub1"]}
          >
            <Menu.Item key="1">
              <Link to="/application/dashboard" />
              <i className="fa fa-tachometer fa-lg" aria-hidden="true"></i>
              <span className="menuitem">Dashboard</span>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/application/users" />
              <i className="fa fa-users fa-lg" aria-hidden="true"></i>
              <span className="menuitem">Users</span>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/application/missedLeads" />
              <i className="fa fa-sitemap fa-lg" aria-hidden="true"></i>
              <span className="menuitem">Missed Leads</span>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/application/advertisements" />
              <i
                className="fa fa-audio-description fa-lg"
                aria-hidden="true"
              ></i>
              <span className="menuitem">Ads</span>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/application/subscriptions" />
              <i className="fa fa-credit-card-alt fa-lg" aria-hidden="true"></i>
              <span className="menuitem">Subscriptions</span>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/application/contactForm" />
              <i className="fa fa-address-card fa-lg" aria-hidden="true"></i>
              <span className="menuitem">Contact Form</span>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/application/mortgage" />
              <i className="fa fa-calculator fa-lg" aria-hidden="true"></i>
              <span className="menuitem">Mortgage Cal</span>
            </Menu.Item>
            <Menu.Item key="8">
              <Link to="/application/configuration" />
              <i className="fa fa-cogs fa-lg" aria-hidden="true"></i>
              <span className="menuitem">APIs</span>
            </Menu.Item>
            <Menu.Item key="10" onClick={this.onLogout}>
              <i className="fa fa-sign-out fa-lg" aria-hidden="true"></i>
              <span className="menuitem">Logout</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}
          <Content style={{ margin: "0 16px" }}>
            <Switch>
              {AppRoutes.map((route) => (
                <Route
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                  key={route.key}
                />
              ))}
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>HomesListedBy ©2021</Footer>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(Application);
